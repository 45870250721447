import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createTopupAction } from '../../../store/actions/TopupActions';
import { getVendorsAction } from '../../../store/actions/VendorsActions';


function NewTopup({ open, handleClose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { VENDORS_LIST, isLoading } = useSelector((state) => state.vendors);
  const [searchTerm, setSearchTerm] = useState('');
  const [timer, setTimer] = useState(null);


  useEffect(() => {
    if (timer) clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      setLoading(true)
      await dispatch(getVendorsAction({ searchName: searchTerm }));
      setLoading(false)
    }, 1000);
    setTimer(newTimer);

  }, [searchTerm])

  const handleSearchChange = async (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };


  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue, resetForm } = useFormik({
    initialValues: {
      amount: '',
      vendorID: ''
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required('Amount is required'),
      vendorID: Yup.string().required('Vendor is required')
    }),
    onSubmit: async (values) => {
      const response = await dispatch(createTopupAction(values));
      if (response?.error === 1) {
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      } else {
        resetForm();
        handleClose();
      }
    }
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Topup Wallet</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Top up wallet so you can have access to run Ad campaigns.
            </DialogContentText>

            <Autocomplete
              options={VENDORS_LIST}
              getOptionLabel={(option) => option.fullname}
              onInputChange={handleSearchChange}
              onChange={(event, value) => setFieldValue('vendorID', value ? value.id : '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Vendor"
                  variant="outlined"
                  margin="normal"
                  error={Boolean(touched.vendorID && errors.vendorID)}
                  helperText={touched.vendorID && errors.vendorID}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
            <TextField
              autoFocus
              margin="normal"
              id="amount"
              label="Amount"
              type="text"
              fullWidth
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={isLoading}>
              Create Topup
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default NewTopup;
