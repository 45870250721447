import { toast } from 'react-toastify';
import {
  getVendorsRequest,
  logoutVendorRequest,
  resetVendorPasswordRequest,
  searchVendorsRequest,
  updateVendorRequest
} from '../../api/VendorsRequest';
import { GET_VENDORS, LOAD_DONE, LOAD_REQUEST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getVendorsAction =
  (values = {}) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: LOAD_REQUEST });

        const { data } = await getVendorsRequest(values);

        const error = checkIfResponseOk(data, dispatch);
        if (error) return error;


        const state = getState();
        if (values?.page && values?.page > 1) {
          dispatch({ type: GET_VENDORS, payload: [...state.vendors.VENDORS_LIST, ...data.content.data.all] });
          return 0;
        } else {
          dispatch({ type: GET_VENDORS, payload: data.content.data.all });
          return 0;
        }

      } catch (err) {
        dispatch({ type: LOAD_DONE });
        return { error: 1, message: err.message };
      }
    };

export const updateVendorAction = (id, obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await updateVendorRequest(id, reqObj);

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    toast.success('Updated Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    dispatch(getVendorsAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const resetVendorPasswordAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await resetVendorPasswordRequest(id);

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    toast.success('Password Reset Sucessful!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    dispatch(getVendorsAction());
    return data.success;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const logoutVendorAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await logoutVendorRequest(id);

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    toast.success('Vendor Logged out Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    dispatch(getVendorsAction());
    return data.success;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const searchVendorsAction = (value) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await searchVendorsRequest(value);

    const error = checkIfResponseOk(data, dispatch);
    if (error) return error;

    dispatch({ type: GET_VENDORS, payload: data.content.data?.all || [] });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
