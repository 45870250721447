import axios from './index';

export const getVendorsRequest = (values) =>
  axios.get(
    `/v1/admins/get/vendors/${values?.page || '1'}/${values?.limit || '50'}/${values?.id || '-'}/${values?.searchName || '-'
    }/${values?.from || '-'}/${values?.to || '-'}/${values?.accessStatus || '-'}/${values?.phoneVerified || '-'
    }/${values?.emailVerified || '-'}`
  );

export const updateVendorRequest = (vendorID, reqObj) =>
  axios.put(`/v1/admins/update/vendor/${vendorID}`, reqObj);

export const resetVendorPasswordRequest = (vendorID) =>
  axios.put(`/v1/admins/reset/vendor/password/${vendorID}`);

export const logoutVendorRequest = (vendorID) => axios.post(`/v1/admins/logout/vendor/${vendorID}`);

export const searchVendorsRequest = (values) =>
  axios.get(
    `/v1/admins/get/vendors/${values?.page || '1'}/${values?.limit || '50'}/${values?.id || '-'}/${values?.searchName || '-'
    }/${values?.from || '-'}/${values?.to || '-'}/${values?.accessStatus || '-'}/${values?.phoneVerified || '-'
    }/${values?.emailVerified || '-'}`
  );
