import axios from 'axios';
import { logoutAction } from '../store/actions/AuthActions';
import { toast } from 'react-toastify';

export const baseURL = process.env.REACT_APP_BASE_URL;

// console.log('NODE ENV ', process.env.REACT_APP_BASE_URL);
// export const baseURL = 'https://apis.touchandpay.me/lamata-bus-wifi';

const axiosInstance = axios.create({
  baseURL
});

axiosInstance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');

  if (token) {
    config.headers = {
      Token: `bearer ${token}`,
      'Content-Type': 'application/json'
    };
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  // to trigger automatic logout if the auth is invalid
  if (response.data?.error?.message.includes('Expired session')) {
    // logoutRequest();
    toast.info('Invalid token. Please login again.');
    setTimeout(() => {
      logoutAction();
    }, 4000);

    //  window.location.pathname = '/';
    console.log('logout refreshed');
  }
  return response;
});

export default axiosInstance;
