import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack,
    Grid,
    Autocomplete
} from '@material-ui/core';
import { getVendorsAction } from 'src/store/actions/VendorsActions';
import { getAdAction } from 'src/store/actions/AdActions';
import { sortArrayBy } from 'src/utils/utilities';
import { getRoutesAction } from 'src/store/actions/RoutesActions';
import {
    getCitiesAction,
    getCountriesAction,
    getStatesAction
} from 'src/store/actions/WorldActions';
import moment from 'moment';

function FilterVendors({ handleFilter, loading }) {
    const [isLoading, setIsLoading] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD')); //gets the last sunday as default fromDate value
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const dispatch = useDispatch();
    const [options, setOptions] = useState({ country: [], state: [], city: [] });
    const { VENDORS_LIST } = useSelector((state) => state.vendors);



    const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } =
        useFormik({
            initialValues: {
                vendorID: '',
                limit: '',
                accessStatus: '',
                dateFrom: '',
                dateTo: '',
                phoneVerified: '',
                emailVerified: ''
            },
            validationSchema: Yup.object().shape({
                vendorID: Yup.string(),

            }),
            onSubmit: handleFilter
        });

    return (
        <div style={{ width: '100%' }}>
            <form onSubmit={handleSubmit}>
                {/* <Stack direction="row" alignItems="center" spacing={5} justifyContent="space-between"> */}
                <Grid container spacing={2}>
                    <Grid item sm={3}>
                        <TextField
                            error={Boolean(touched.vendorID && errors.vendorID)}
                            helperText={touched.vendorID && errors.vendorID}
                            fullWidth
                            select
                            margin="normal"
                            name="vendorID"
                            label="Vendor"
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.vendorID}
                        >
                            <MenuItem value="">Please select Vendor</MenuItem>
                            {Boolean(VENDORS_LIST) &&
                                sortArrayBy(VENDORS_LIST, 'fullname').map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                        {item.fullname}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>

                    <Grid item sm={3}>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="count"
                            label="Fetch Count"
                            type="text"
                            fullWidth
                            value={values.limit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.limit && errors.limit)}
                            helperText={touched.limit && errors.limit}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="dateFrom"
                            label="From Date"
                            type="date"
                            fullWidth
                            defaultValue={fromDate}
                            onChange={(e) => {
                                setFieldValue('dateFrom', moment(e.target.value).unix());
                                setFromDate(e.target.value);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.dateFrom && errors.dateFrom)}
                            helperText={touched.dateFrom && errors.dateFrom}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>

                    <Grid item sm={3}>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="dateTo"
                            label="To Date"
                            type="date"
                            fullWidth
                            defaultValue={toDate}
                            //   value={toDate}
                            onChange={(e) => {
                                setFieldValue('dateTo', moment(e.target.value).unix());
                                setToDate(e.target.value);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.dateTo && errors.dateTo)}
                            helperText={touched.dateTo && errors.dateTo}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            error={Boolean(touched.accessStatus && errors.accessStatus)}
                            helperText={touched.accessStatus && errors.accessStatus}
                            fullWidth
                            select
                            margin="normal"
                            name="accessStatus"
                            label="Status"
                            variant="outlined"
                            onBlur={handleBlur}
                            size="small"
                            onChange={handleChange}
                            value={values.accessStatus}
                        >
                            <MenuItem value="">Please select </MenuItem>
                            <MenuItem value="1">Active</MenuItem>
                            <MenuItem value="0">Inactive</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item sm={3}>
                        <TextField
                            error={Boolean(touched.phoneVerified && errors.phoneVerified)}
                            helperText={touched.phoneVerified && errors.phoneVerified}
                            fullWidth
                            select
                            margin="normal"
                            name="phoneVerified"
                            label="Phone Verify Status"
                            variant="outlined"
                            onBlur={handleBlur}
                            size="small"
                            onChange={handleChange}
                            value={values.phoneVerified}
                        >
                            <MenuItem value="">Please select </MenuItem>
                            <MenuItem value="1">Verified</MenuItem>
                            <MenuItem value="0">Unverified</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item sm={3}>
                        <TextField
                            error={Boolean(touched.emailVerified && errors.emailVerified)}
                            helperText={touched.emailVerified && errors.emailVerified}
                            fullWidth
                            select
                            margin="normal"
                            name="emailVerified"
                            label="Email Verify Status"
                            variant="outlined"
                            onBlur={handleBlur}
                            size="small"
                            onChange={handleChange}
                            value={values.emailVerified}
                        >
                            <MenuItem value="">Please select </MenuItem>
                            <MenuItem value="1">Verified</MenuItem>
                            <MenuItem value="0">Unverified</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <LoadingButton
                            style={{ marginTop: '20px' }}
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={loading}
                        >
                            Filter
                        </LoadingButton>
                    </Grid>
                </Grid>

                {/* </Stack> */}
            </form>
        </div>
    );
}

export default FilterVendors;
