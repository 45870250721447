import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip,
  Tooltip
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Label, Done, Delete, Close } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { fDateTime } from '../../utils/formatTime';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';

import { getAdAction, toggleAdStatusAction } from '../../store/actions/AdActions';

import ViewMedia from './components/ViewMedia';
import FilterAds from './components/FilterAds';
import { fCurrency } from 'src/utils/formatNumber';
import VendorDetails from './components/VendorDetails';
import Table from '../../components/table';

function AdsModule() {
  const dispatch = useDispatch();
  const LIMIT = 50;
  const { isLoading, ADSLIST } = useSelector((state) => state.Ads);
  const [loading, setLoading] = useState(false);

  const [openNewAd, setOpenNewAd] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [showVendorDetails, setShowVendorDetails] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [dataPageFetched, setDataPageFetched] = useState(1);

  const toggleModal = () => {
    setOpenNewAd(!openNewAd);
  };

  const handleShowMedia = (row) => {
    setShowMedia(!showMedia);
    setCurrentRow(row);
  };

  const handleShowVendorDetails = (row) => {
    setShowVendorDetails(!showVendorDetails);
    setCurrentRow(row);
  };

  const handleCloseShowMedia = () => {
    setShowMedia(false);
    setShowVendorDetails(false);
  };

  useEffect(() => {
    dispatch(getAdAction({ page: 1, limit: LIMIT }));
  }, []);

  const _refreshTable = async () => await dispatch(getAdAction());

  const handleToggleStatus = async (id, status) => {
    const action = window.confirm(
      `Are you sure you want to ${status === 1 ? 'activate' : 'deactivate'} this Ad`
    );
    if (action) {
      const response = await dispatch(toggleAdStatusAction(id, status));
      if (response?.error === 1) {
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      }
    }
  };

  const _filterAds = async (values) => {
    setLoading(true);
    const response = await dispatch(getAdAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setLoading(false);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      render: (index, rowData) => rowData?.name || `Ad ${rowData?.id}`
    },
    {
      id: 'actionLink',
      label: 'Action Link',
      align: 'left',
      render: (index, rowData) => rowData.actionLink.substring(0, 20) + '...'
    },
    {
      id: 'caption',
      label: 'Caption',
      align: 'left',
      render: (index, rowData) => rowData.caption.substring(0, 20) + '...'
    },
    {
      id: 'views',
      label: 'Views',
      align: 'center',
      render: (index, rowData) => rowData.views
    },
    {
      id: 'clicks',
      label: 'Clicks',
      align: 'center',
      render: (index, rowData) => rowData.clicks
    },
    {
      id: 'conversions',
      label: 'Conversions',
      align: 'center',
      render: (index, rowData) => rowData.conversions
    },
    {
      id: 'currDailyCost',
      label: 'Daily Cost',
      align: 'left',
      render: (index, rowData) => {
        return fCurrency(rowData.currDailyCost || 0);
      }
    },
    {
      id: 'adType',
      label: 'Ad Type',
      align: 'left'
      // render: (index, rowData) => rowData.adType
    },
    {
      id: 'conversionUrl',
      label: 'Conversion Url',
      align: 'left',
      render: (index, rowData) => (
        <>
          <kbd>curl --location --request PUT </kbd> <code>{rowData.conversionUrl}</code>
        </>
      )
    },
    {
      id: 'activeStatus',
      label: 'Status',
      align: 'left',
      render: (index, rowData) => (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Chip
            label={rowData.activeStatus == 1 ? 'Active' : 'Inactive'}
            color={rowData.activeStatus == 1 ? 'primary' : 'default'}
            clickable
          />
          {rowData.activeStatus == 1 ? (
            <Close
              style={{ cursor: 'pointer' }}
              onClick={() => handleToggleStatus(rowData.id, 0)}
            />
          ) : (
            <Done style={{ cursor: 'pointer' }} onClick={() => handleToggleStatus(rowData.id, 1)} />
          )}
        </Stack>
      )
    },
    {
      id: 'dateCreated',
      label: 'Time Paid',
      render: (index, rowData) => fDateTime(rowData.dateCreated),
      align: 'left'
    },
    {
      id: 'action',
      label: 'Summary',
      render: (index, rowData) => (
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={() => handleShowMedia(rowData)}>
            Summary
          </Button>
          <Button variant="outlined" onClick={() => handleShowVendorDetails(rowData)}>
            Details
          </Button>
        </Stack>
      ),
      align: 'left'
    }
  ];

  const handleGetMoreData = () => {
    const nextPage = Math.ceil(ADSLIST.length / LIMIT) + 1;
    dispatch(getAdAction({ page: nextPage, limit: LIMIT }));
    setDataPageFetched((prev) => nextPage);
  };

  return (
    <Page title="Ads Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ads
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <FilterAds handleFilter={_filterAds} loading={loading} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={ADSLIST}
          isLoading={isLoading}
          filterField="name"
          tableTitle="Ads"
          getMoreData={handleGetMoreData}
        />
        {/* Modals */}

        <ViewMedia open={showMedia} handleClose={handleCloseShowMedia} rowData={currentRow} />
        <VendorDetails
          open={showVendorDetails}
          handleClose={handleCloseShowMedia}
          rowData={currentRow}
        />
      </Container>
    </Page>
  );
}

export default AdsModule;
