import { toast } from 'react-toastify';
import { getAdsRequest, toggleStatusRequest } from '../../api/AdRequest';
import { EMPTY_LIST, GET_ADS, GET_ROUTES, LOAD_DONE, LOAD_REQUEST } from '../types';

export const getAdAction = (values) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getAdsRequest(values);
    if (data?.content?.data == null) {
      dispatch({ type: EMPTY_LIST });
      return { error: 1, message: 'No Results found!' };
    } else if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    const state = getState();
    if (values?.page && values?.page > 1) {
      dispatch({ type: GET_ADS, payload: [...state.Ads.ADSLIST, ...data.content.data.all] });
      return 0;
    } else {
      dispatch({ type: GET_ADS, payload: data.content.data.all });
      return 0;
    }

  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const checkIfResponseOk = (response) => {
  if (response?.error?.status === 1) {
    return { error: 1, message: response?.error?.message };
  }
};

export const toggleAdStatusAction = (id, status) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await toggleStatusRequest(id, status);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Ad Status Changed Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getAdAction());
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};
